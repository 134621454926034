/* Book gallery section styling */

.book-gallery {
  text-align: center;
  padding: 2rem;
}

.books {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

.book {
  width: 300px;
  text-align: center;
}

.image-container {
  width: 100%;          /* Full width of the card */
  height: 350px;        /* Fixed height for image container */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px;
  background-color: #f3f3f3; /* Optional background to frame images */
}

.book-image {
  max-width: 100%;      /* Scales the image within the container */
  max-height: 100%;     /* Ensures image fits within the height without cropping */
}

/* Bio section styling */
/* Responsive styling for bio at the bottom */

.bio {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  gap: 1.5rem;
  flex-direction: row; /* Default layout: image and text side-by-side */
}

.bio-image {
  width: 120px;
  height: 120px;
  border-radius: 50%; /* Circular image */
  object-fit: cover;   /* Ensures the image covers without distortion */
}

.bio-text {
  max-width: 600px;
}


/* Contact section styling */

.contact {
  text-align: center;
  padding: 2rem;
}
.contact a {
  color: #6a1b9a;
  text-decoration: none;
}

.contact-link {
  color: #6a1b9a; /* Adjust color as needed */
  text-decoration: underline;
}

.contact-link:hover {
  text-decoration: none; /* Optional: removes underline on hover */
}


/* App styling */

.App {
  font-family: Arial, sans-serif;
  color: #333;
}

footer {
  background-color: #333;
  color: white;
  padding: 1rem;
  text-align: center;
  margin-top: 2rem;
}

/* Logo styling */

.logo {
  display: block;
  margin: 0 auto;
  width: 150px; /* Adjust as needed for logo size */
  padding: 1rem 0;
}

header {
  background-color: transparent; /* Removes the purple bar */
  padding: 1rem;
  text-align: center;
}

/* Welcome message styling */

.welcome-message {
  text-align: center;
  padding: 1.5rem;
  font-size: 1.2rem;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
}

/* Website click through URL styling */
.order-link {
  display: inline-block;
  margin-top: 0.5rem;
  color: #ffffff;
  background-color: #6a1b9a;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
}

.order-link:hover {
  background-color: #5a0f7a; /* Darken color on hover */
}


/* Bio styling - Responsive layout for mobile devices */
@media (max-width: 600px) {
  .bio {
    flex-direction: column; /* Stacks image above text */
    text-align: center;     /* Centers content for a balanced look */
  }

  .bio-image {
    margin-bottom: 1rem; /* Adds space between image and text */
  }

  .bio-text {
    max-width: 100%;      /* Full width on mobile */
  }
}